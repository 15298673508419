// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import HomeOutlined from 'C:/Users/10694/Desktop/xm/tiktok/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/HomeOutlined';
import CreditCardOutlined from 'C:/Users/10694/Desktop/xm/tiktok/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/CreditCardOutlined';
import UserOutlined from 'C:/Users/10694/Desktop/xm/tiktok/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/UserOutlined';
import FileTextOutlined from 'C:/Users/10694/Desktop/xm/tiktok/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/FileTextOutlined';
import WechatOutlined from 'C:/Users/10694/Desktop/xm/tiktok/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/WechatOutlined';
import UserSwitchOutlined from 'C:/Users/10694/Desktop/xm/tiktok/node_modules/.pnpm/@ant-design+icons@4.8.3_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/@ant-design/icons/es/icons/UserSwitchOutlined';
export default { HomeOutlined, CreditCardOutlined, UserOutlined, FileTextOutlined, WechatOutlined, UserSwitchOutlined };
