import { apiUrl, geetestUrl, geevisitUrl, passportUrl } from '@/config/url';
import { request } from '@/utils/request';

// 查询智联账号基本信息
export async function queryZlAccountInfo(cookie: string) {
  return request(`${apiUrl}/api/session`, {
    method: 'GET',
    headers: {
      fusheng: cookie,
      SuperCookie: cookie,
    },
  });
}

// 请求 https://passport.zhaopin.com/passportService/verify/init
export async function verifyInit() {
  return request(`${passportUrl}/passportService/verify/init`, {
    method: 'GET',
    params: {
      verifyType: '1',
      appID: '5fd8e94989e64715a3a1f100a3511b0b',
      _: Date.now(),
    },
    // headers: {
    //   fusheng: cookie,
    //   SuperCookie: cookie,
    // },
  });
}

// "https://api.geevisit.com/ajax.php"
export async function geeVisit(data: any) {
  const { gt, challenge } = data;
  return request(`${geevisitUrl}/ajax.php`, {
    method: 'GET',
    params: {
      gt: gt,
      challenge: challenge,
      lang: 'zh-cn',
      pt: '0',
      client_type: 'web',
      w: '',
      callback: 'geetest_' + Date.now(),
    },
  });
}

// gettype.php params gt
export async function geeVisitGetType(gt: string) {
  return request(`${geetestUrl}/gettype.php`, {
    method: 'GET',
    params: {
      gt: gt,
      callback: 'geetest_' + Date.now(),
    },
  });
}

// get.php params gt challenge
export async function geeVisitGet(gt: string, challenge: string) {
  return request(`${geetestUrl}/get.php`, {
    method: 'GET',
    params: {
      gt: gt,
      challenge: challenge,
      lang: 'zh-cn',
      pt: '0',
      client_type: 'web',
      w: '',
      callback: 'geetest_' + Date.now(),
    },
  });
}

// https://api.geevisit.com/get.php  get gt challenge
export async function geeVisitGet2(gt: string, challenge: string) {
  return request(`${geevisitUrl}/get.php`, {
    method: 'GET',
    params: {
      is_next: 'true',
      type: 'slide3',
      gt: gt,
      challenge: challenge,
      lang: 'zh-cn',
      https: 'false',
      protocol: 'https://',
      offline: 'false',
      product: 'embed',
      api_server: 'api.geevisit.com',
      isPC: 'true',
      autoReset: 'true',
      width: '100%',
      callback: 'geetest_' + Date.now(),
    },
  });
}

// 账号登陆 /org/api/login/account
export async function loginAccount(data: any) {
  const { username, password, challenge, validateId, validate, deviceId } =
    data;
  return request(`${passportUrl}/org/api/login/account`, {
    method: 'POST',
    params: {
      _: Date.now(),
      'x-zp-page-request-id':
        'b5836c4a40644897a7c59c5c80cd39aa-1710920891069-406464',
      'x-zp-client-id': 'ac12ff0e-c8bd-4438-9325-443b6060d74a',
    },
    data: {
      name: username,
      password: password,
      campusOrgId: '',
    },
    headers: {
      'x-zp-geestest-validate-id': validateId,
      'x-zp-geestest-challenge': challenge,
      'x-zp-geestest-validate': validate,
      'x-zp-geestest-sec-code': `${validate}|jordan`,
      fusheng: `x-zp-device-id=${deviceId}`,
      SuperCookie: `x-zp-device-id=${deviceId}`,
    },
  });
}

// 续命1 /api/app/notice/center/getNewNotice  post
export async function getNewNotice(cookie: any) {
  return request(`${apiUrl}/api/app/notice/center/getNewNotice`, {
    method: 'POST',
    headers: {
      SuperCookie: cookie,
    },
  });
}

// 续命2 /api/app/notice/center/checkWhetherShowDot  get
export async function checkWhetherShowDot(cookie: any) {
  return request(`${apiUrl}/api/app/notice/center/checkWhetherShowDot`, {
    method: 'GET',
    params: {
      _: Date.now(),
      'x-zp-page-request-id':
        'e5fc8e517ad34faba8e60492d9d4facf-1712219540912-333611',
      'x-zp-client-id': '83599a91-2d9e-4111-9fd9-b719d1631dcc',
    },
    headers: {
      SuperCookie: cookie,
    },
  });
}

// /app/index get
export async function getZhiLianIndex(cookie: any) {
  return request(`${apiUrl}/app/index`, {
    method: 'GET',
    headers: {
      SuperCookie: cookie,
    },
  });
}
