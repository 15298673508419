import { ResponseData } from '@/services/types';
import { request } from '@/utils/request';

// 获取验证码
export async function getCaptcha(data: any) {
  return request('/login/getRegisterCode', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
}

// 注册账号
export async function register(data: any) {
  return request('/user/add/general', {
    method: 'POST',
    data,
  });
}

// 密码登录
export async function login(data: any) {
  return request<ResponseData<string>>('/login/password', {
    method: 'POST',
    data,
  });
}

// 验证码登录
export async function loginByCode(data: any) {
  return request<ResponseData<string>>('/login/code', {
    method: 'POST',
    data,
  });
}

// 退出登录
export async function logout() {
  return request('/login/loginOut', {
    method: 'POST',
  });
}

// 超管验证码登录 /admin/code
export async function adminLoginByCode(data: any) {
  return request<ResponseData<string>>('/admin/code', {
    method: 'POST',
    data,
  });
}
