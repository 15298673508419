import Header from './components/Header';

import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { autoFixContext } from 'react-activation';
import jsxDevRuntime from 'react/jsx-dev-runtime';
import jsxRuntime from 'react/jsx-runtime';
dayjs.locale('zh-cn');

// 判断是不是移动端
const isMobile = () => {
  return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
};
// 是移动端的话自动横屏
if (isMobile()) {
  window.screen.orientation
    .lock('landscape')
    .then((a) => {})
    .catch((e) => {});
}

autoFixContext(
  [jsxRuntime, 'jsx', 'jsxs', 'jsxDEV'],
  [jsxDevRuntime, 'jsx', 'jsxs', 'jsxDEV'],
);
// 运行时配置

// 全局初始化数据配置，用于 Layout 用户信息和权限初始化
// 更多信息见文档：https://umijs.org/docs/api/runtime-config#getinitialstate
export async function getInitialState(): Promise<{
  name?: string;
  userInfo: any;
}> {
  return { name: '智联助手', userInfo: null };
}

export const layout = () => {
  return {
    menu: {
      locale: false,
    },
    layout: 'mix',
    collapsedButtonRender: () => null,
    headerRender: () => <Header />,
    siderWidth: 220,
  };
};
