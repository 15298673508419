import { keepAlive, keepToken, userInfoRequest } from '@/services/user';
import { User } from '@/services/user/types.d';
import { useModel } from '@umijs/max';
import { useEffect, useState } from 'react';

export default () => {
  const { setInitialState } = useModel('@@initialState');
  //用户信息
  const [userInfo, setUserInfo] = useState<User | null>(null);
  // 失败信息列表
  const [failList, setFailList] = useState<string[]>([]);

  // 续命
  const keepAlivefn = () => {
    keepAlive();
    keepToken();
    // 循环调用
    setInterval(() => {
      keepAlive();
      keepToken();
    }, 1000 * 60 * 30);
  };

  // 获取用户信息
  const getUserInfo = () => {
    // 获取tooken
    const token = localStorage.getItem('token');
    // 获取手机号
    const phone = localStorage.getItem('phone');
    // 如果有tooken, 则获取用户信息
    if (token && phone) {
      userInfoRequest({ phone }).then((res) => {
        if (res.data) {
          setUserInfo(res.data);
          // 将用户信息存入全局
          setInitialState({
            userInfo: res.data,
          });
          keepAlivefn();
        }
      });
    } else {
      setUserInfo(null);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  // 判断fusheng数据库是否存在，不存在则创建
  useEffect(() => {
    // 获取chatInfo数据库版本号
    const chatInfoVersion = localStorage.getItem('chatInfoVersion');
    // 创建微聊用户信息列表数据库
    const request = window.indexedDB.open(
      'chatInfo',
      Number(chatInfoVersion) || 1,
    );
    // 将版本号保存到localstorage中
    if (!chatInfoVersion) {
      localStorage.setItem('chatInfoVersion', '1');
    }
    request.onerror = function (event) {};
    request.onsuccess = function (event) {};

    // 获取chatLog数据库版本号
    const chatLogVersion = localStorage.getItem('chatLogVersion');
    // 创建微聊日志数据库
    const requestLog = window.indexedDB.open(
      'chatLog',
      Number(chatLogVersion) || 1,
    );
    // 将版本号保存到localstorage中
    if (!chatLogVersion) {
      localStorage.setItem('chatLogVersion', '1');
    }
    requestLog.onerror = function (event) {};
    requestLog.onsuccess = function (event) {};

    // 检查localStorage中是否有chatSwitchList，没有则创建
    if (!localStorage.getItem('chatSwitchList')) {
      localStorage.setItem('chatSwitchList', JSON.stringify([]));
    }

    // 获取发帖日志数据库版本号
    const postLogVersion = localStorage.getItem('postLogVersion');
    // 创建发帖日志数据库
    const requestPostLog = window.indexedDB.open(
      'postLog',
      Number(postLogVersion) || 1,
    );
    // 将版本号保存到localstorage中
    if (!postLogVersion) {
      localStorage.setItem('postLogVersion', '1');
    }
    requestPostLog.onerror = function (event) {};
    requestPostLog.onsuccess = function (event) {};
  }, []);

  // 监听错误信息和失败信息
  useEffect(() => {
    window.addEventListener('error', (e: any) => {
      setFailList((prev) => [...prev, e.detail]);
    });
  }, []);

  return { getUserInfo, userInfo, setUserInfo, failList, setFailList };
};
