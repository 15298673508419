import category from '@/config/category.json';
import {
  geeVisit,
  geeVisitGet,
  geeVisitGetType,
  loginAccount,
  verifyInit,
} from '@/services/zlAccount';

export const uuid = (len: number, r: number): string => {
  const chars =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid: string[] = [];
  let radix = r || chars.length;

  if (len) {
    // Compact form
    for (let i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    let r: number;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join('');
};

// 退出登录方法
export const logoutCleanTooken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('phone');
  window.location.reload();
};

// 根据传参生成范围内随机整数
export const randomNum = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

// 根据传入的开始截止时间，判断当前时间是否在范围内，传入日期为HH:mm:ss
export const isBetweenTimeUtil = (s: string, e: string): any => {
  if (!s && !e)
    return {
      is: true,
    };
  if (!s || !e) return false;
  const startTime = new Date();
  const endTime = new Date();
  const currentTime = new Date();
  startTime.setHours(Number(s.split(':')[0]));
  startTime.setMinutes(Number(s.split(':')[1]));
  startTime.setSeconds(Number(s.split(':')[2]));
  endTime.setHours(Number(e.split(':')[0]));
  endTime.setMinutes(Number(e.split(':')[1]));
  endTime.setSeconds(Number(e.split(':')[2]));
  if (
    currentTime.getTime() > startTime.getTime() &&
    currentTime.getTime() < endTime.getTime()
  ) {
    return {
      s: startTime.getTime(),
      e: endTime.getTime(),
      c: currentTime.getTime(),
      is: true,
    };
  }
  {
    return {
      s: startTime.getTime(),
      e: endTime.getTime(),
      c: currentTime.getTime(),
      is: false,
    };
  }
};

// 判断微聊用户信息表是否存在，不存在则创建
export const isTableExist = (tableName: string) => {
  // 获取数据库版本号
  const chatInfoVersion = localStorage.getItem('chatInfoVersion')
    ? Number(localStorage.getItem('chatInfoVersion'))
    : 1;
  const request = window.indexedDB.open('chatInfo', chatInfoVersion + 1);
  // 将版本号保存到localstorage中
  localStorage.setItem('chatInfoVersion', String(chatInfoVersion + 1));
  request.onerror = function (event) {};
  request.onsuccess = function (event) {};
  request.onupgradeneeded = function (event) {
    const db = event.target.result;
    if (!db.objectStoreNames.contains(tableName)) {
      const objectStore = db.createObjectStore(tableName, { keyPath: 'id' });
      // id索引，不可重复
      objectStore.createIndex('id', 'id', { unique: true });
    }
  };
};

// 判断微聊日志表是否存在，不存在则创建
export const isTableExistLog = (tableName: string) => {
  // 获取数据库版本号
  const chatLogVersion = localStorage.getItem('chatLogVersion')
    ? Number(localStorage.getItem('chatLogVersion'))
    : 1;
  const request = window.indexedDB.open('chatLog', chatLogVersion + 1);
  // 将版本号保存到localstorage中
  localStorage.setItem('chatLogVersion', String(chatLogVersion + 1));
  request.onerror = function (event) {};
  request.onsuccess = function (event) {};
  request.onupgradeneeded = function (event) {
    const db = event.target.result;
    if (!db.objectStoreNames.contains(tableName)) {
      const objectStore = db.createObjectStore(tableName, { keyPath: 'time' });
      // id索引，不可重复
      objectStore.createIndex('time', 'time', { unique: true });
    }
  };
};
// 判断发帖日志表是否存在，不存在则创建
export const isTablePostLog = (tableName: string) => {
  // 获取数据库版本号
  const postLogVersion = localStorage.getItem('postLogVersion')
    ? Number(localStorage.getItem('postLogVersion'))
    : 1;
  const request = window.indexedDB.open('postLog', postLogVersion + 1);
  // 将版本号保存到localstorage中
  localStorage.setItem('postLogVersion', String(postLogVersion + 1));
  request.onerror = function (event) {};
  request.onsuccess = function (event) {};
  request.onupgradeneeded = function (event) {
    const db = event.target.result;
    if (!db.objectStoreNames.contains(tableName)) {
      const objectStore = db.createObjectStore(tableName, { keyPath: 'time' });
      // id索引，不可重复
      objectStore.createIndex('time', 'time', { unique: true });
    }
  };
};

// 根据传参中的表名向indexDB中微聊用户信息的指定表插入数据
export const insertDataToIndexDB = (tableName: string, data: any) => {
  const chatInfoVersion = localStorage.getItem('chatInfoVersion');
  const request = window.indexedDB.open('chatInfo', Number(chatInfoVersion));

  request.onerror = function (event) {};
  request.onsuccess = function (event) {
    const db = event.target.result;
    // 判断表是否存在
    if (!db.objectStoreNames.contains(tableName)) {
      return;
    }
    const transaction = db.transaction(tableName, 'readwrite');
    const store = transaction.objectStore(tableName);
    store.put(data);
  };
};

// 根据传参中的表名和id获取indexDB中微聊用户信息的指定表的指定数据
export const getDataFromIndexDB = (tableName: string, id?: string) => {
  return new Promise((resolve, reject) => {
    const chatInfoVersion = localStorage.getItem('chatInfoVersion');
    const request = window.indexedDB.open('chatInfo', Number(chatInfoVersion));
    // 获取数据
    request.onerror = function (event) {};
    request.onsuccess = function (event) {
      const db = event.target.result;
      // 判断表是否存在
      if (!db.objectStoreNames.contains(tableName)) {
        resolve(null);
        return;
      }
      const transaction = db.transaction(tableName, 'readwrite');
      const store = transaction.objectStore(tableName);
      const result = id ? store.get(Number(id)) : store.getAll();
      result.onsuccess = function (event) {
        resolve(result.result);
      };
    };
  });
};

// 根据传参中的表名向indexDB中微聊日志数据库的指定表插入数据
export const insertDataToIndexDBLog = (tableName: string, data: any[]) => {
  const chatLogVersion = localStorage.getItem('chatLogVersion');
  const request = window.indexedDB.open('chatLog', Number(chatLogVersion));

  request.onerror = function (event) {};
  request.onsuccess = function (event) {
    const db = event.target.result;
    const transaction = db.transaction(tableName, 'readwrite');
    const store = transaction.objectStore(tableName);
    // store.put(data);
    for (let i = 0; i < data.length; i++) {
      store.put(data[i]);
    }
  };
};
// 根据传参中的表名向indexDB中发帖日志数据库的指定表插入数据
export const postInsertDataToIndexDBLog = (tableName: string, data: any) => {
  const postLogVersion = localStorage.getItem('postLogVersion');
  const request = window.indexedDB.open('postLog', Number(postLogVersion));

  request.onerror = function (event) {};
  request.onsuccess = function (event) {
    const db = event.target.result;
    const transaction = db.transaction(tableName, 'readwrite');
    const store = transaction.objectStore(tableName);
    store.put(data);
  };
};

// 通过表名获取微聊日志数据
export const getDataFromIndexDBLog = (tableName: string) => {
  return new Promise((resolve, reject) => {
    const chatLogVersion = localStorage.getItem('chatLogVersion');
    const request = window.indexedDB.open('chatLog', Number(chatLogVersion));

    request.onerror = function (event) {};
    request.onsuccess = function (event) {
      try {
        const db = event.target.result;
        const transaction = db?.transaction(tableName, 'readwrite');
        const store = transaction.objectStore(tableName);
        const result = store.getAll();
        result.onsuccess = function (event) {
          resolve(result.result);
        };
      } catch {
        resolve([]);
      }
    };
  });
};

// 通过表名获取发帖日志数据
export const getPostDataFromIndexDBLog = (tableName: string) => {
  return new Promise((resolve, reject) => {
    const postLogVersion = localStorage.getItem('postLogVersion');
    const request = window.indexedDB.open('postLog', Number(postLogVersion));

    request.onerror = function (event) {};
    request.onsuccess = function (event) {
      try {
        const db = event.target.result;
        const transaction = db?.transaction(tableName, 'readwrite');
        const store = transaction.objectStore(tableName);
        const result = store.getAll();
        result.onsuccess = function (event) {
          resolve(result.result);
        };
      } catch {
        resolve([]);
      }
    };
  });
};

// 根据表名清空指定表的日志
export const clearDataFromIndexDBLog = (tableName: string) => {
  return new Promise((resolve, reject) => {
    try {
      const chatLogVersion = localStorage.getItem('chatLogVersion');
      const request = window.indexedDB.open('chatLog', Number(chatLogVersion));

      request.onerror = function (event) {};
      request.onsuccess = function (event) {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(tableName)) {
          resolve(true);
          return;
        }
        const transaction = db.transaction(tableName, 'readwrite');
        const store = transaction.objectStore(tableName);
        const result = store.clear();
        result.onsuccess = function (event) {
          resolve(true);
        };
      };
    } catch (e) {
      resolve(e.message);
    }
  });
};
// 清空发帖日志
export const clearPostDataFromIndexDBLog = (tableName: string) => {
  return new Promise((resolve, reject) => {
    const postLogVersion = localStorage.getItem('postLogVersion');
    const request = window.indexedDB.open('postLog', Number(postLogVersion));

    request.onerror = function (event) {};
    request.onsuccess = function (event) {
      const db = event.target.result;
      // 判断表是否存在
      if (!db.objectStoreNames.contains(tableName)) {
        resolve(true);
        return;
      }
      const transaction = db.transaction(tableName, 'readwrite');
      const store = transaction.objectStore(tableName);
      const result = store.clear();
      result.onsuccess = function (event) {
        resolve(true);
      };
    };
  });
};

// 传入两个时间，返回第二个和第一个时间之间的分钟数
/**
 * @param t1 第一个时间
 * @param t2 第二个时间
 */
export const getMinutesBetweenTwoTime = (t1: string, t2: string): number => {
  const time1 = new Date();
  const time2 = new Date();
  time1.setHours(Number(t1.split(':')[0]));
  time1.setMinutes(Number(t1.split(':')[1]));
  time2.setHours(Number(t2.split(':')[0]));
  time2.setMinutes(Number(t2.split(':')[1]));
  return (time2.getTime() - time1.getTime()) / 1000 / 60;
};

// 带参防抖
export const debounce = (fn: any, delay = 400) => {
  let timer: any = null;
  return function (this: any, ...args: any[]) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
};

// 获取url所有参数
export const getQueryString = () => {
  const url = window.location.href;
  const index = url.indexOf('?');
  const queryStr = url.substr(index + 1);
  const queryArr = queryStr.split('&');
  const queryObj: any = {};
  queryArr.forEach((item) => {
    const key = item.split('=')[0];
    const value = item.split('=')[1];
    queryObj[key] = value;
  });
  return queryObj;
};

// 获取所有三级选项
export const getAllOptions = (data: any[]) => {
  let arr: any[] = [];
  data.forEach((item) => {
    if (item.children.length > 0) {
      arr = [...arr, ...getAllOptions(item.children)];
    } else {
      arr.push({
        value: item.value,
        label: item.label,
      });
    }
  });
  return arr;
};

// 根据传入的编号获取对应的职位类别，三级联动编号通过&拼接, 联动数据是category，结构value和children
export const getJobCategory = (code: string) => {
  let jobSub = '';
  const are = getAllOptions(category);
  jobSub = are.find((item) => item.value === Number(code)).label;
  return jobSub;
};

// 通过&凭借的最后一个值递归获取对应的serial
export const getSerialByCode = (code: string) => {
  // 是否含有&
  const hasAnd = code.indexOf('&') > -1;
  let serial = '';
  const getCode = (value = code, data = category) => {
    if (hasAnd) {
      const arr = value.split('&');
      let result: any = data;
      // 截取第一个编号
      const firstCode = arr.shift();
      // 根据第一个编号获取第一级数据
      const firstData = result.find(
        (item: any) => item.value === Number(firstCode),
      );
      if (arr.length) {
        getCode(arr.join('&'), firstData.children);
      } else {
        serial = firstData.serial;
      }
    } else {
      const are = getAllOptions(category);
      serial = are.find((item) => item.value === Number(value)).serial;
    }
  };
  getCode();
  return serial;
};

// 通过职位性质和工作性质获取对应的薪资类型
export const getSalaryTypeByJobType = (
  recruitmentType: string,
  employmentType: number,
) => {
  if (!recruitmentType || !employmentType) return false;
  if (recruitmentType === 'NORMAL' && employmentType === 2) {
    return 'MONTHLY_SALARY';
  }
  if (recruitmentType === 'NORMAL' && employmentType === 1) {
    return 'MONTHLY_SALARY';
  }
  if (recruitmentType === 'NORMAL' && employmentType === 4) {
    return 'DAILY_SALARY';
  }
  if (recruitmentType === 'NORMAL' && employmentType === 5) {
    return 'MONTHLY_SALARY';
  }
  if (recruitmentType === 'LABOR_DISPATCH' && employmentType === 2) {
    return 'MONTHLY_SALARY';
  }
  if (recruitmentType === 'LABOR_DISPATCH' && employmentType === 1) {
    return 'MONTHLY_SALARY';
  }
  if (recruitmentType === 'LABOR_DISPATCH' && employmentType === 4) {
    return 'DAILY_SALARY';
  }
  if (recruitmentType === 'LABOR_DISPATCH' && employmentType === 5) {
    return 'MONTHLY_SALARY';
  }
  if (recruitmentType === 'AGENT_RECRUITMENT' && employmentType === 2) {
    return 'MONTHLY_SALARY';
  }
  if (recruitmentType === 'AGENT_RECRUITMENT' && employmentType === 1) {
    return 'MONTHLY_SALARY';
  }
  if (recruitmentType === 'AGENT_RECRUITMENT' && employmentType === 4) {
    return 'DAILY_SALARY';
  }
  if (recruitmentType === 'AGENT_RECRUITMENT' && employmentType === 5) {
    return 'MONTHLY_SALARY';
  }
};

// 传入一个数组，随机返回数组中的一个元素
export const getRandomItemFromArray = (arr: any[]) => {
  if (!arr?.length) return false;
  if (arr.length === 1) return arr[0];
  const index = Math.floor(Math.random() * arr.length);
  return arr[index];
};

export function asyncPool(poolLimit, array, iteratorFn) {
  let i = 0;
  const ret = [];
  const executing = [];
  const enqueue = function () {
    // ① 边界条件，array 为空或者 promise 都已达到 resolve 状态
    if (i === array.length) {
      return Promise.resolve();
    }
    const item = array[i++];

    // ② 生成一个 promise 实例，并在 then 方法中的 onFullfilled 函数里返回实际要执行的 promise，
    const p = Promise.resolve().then(() => iteratorFn(item, array));
    ret.push(p);

    // ④ 将执行完毕的 promise 移除
    const e = p.then(() => executing.splice(executing.indexOf(e), 1));
    // ③ 将正在执行的 promise 插入 executing 数组
    executing.push(e);

    let r = Promise.resolve();
    // ⑥ 如果正在执行的 promise 数量达到了并发限制，则通过 Promise.race 触发新的 promise 执行
    if (executing.length >= poolLimit) {
      r = Promise.race(executing);
    }

    // ⑤ 递归执行 enqueue，直到满足 ①
    return r.then(() => enqueue());
  };
  return enqueue().then(() => Promise.all(ret));
}

//
export function sleep(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

// 自动登录方法
export const geta = async (d: any) => {
  return new Promise(async (resolve, reject) => {
    if (!d) resolve(false);
    const { username, password, deviceId } = d;
    if (!username || !password || !deviceId) resolve(false);
    await sleep(2000);
    const res1: any = await verifyInit();
    const challenge = res1.data.geetestData.challenge;
    const validateId = res1.data.validateId;
    const gt = res1.data.geetestData.gt;
    await geeVisitGetType(gt);
    await geeVisitGet(gt, challenge);
    const validate: any = await geeVisit({ gt, challenge });
    const re = validate.split('(')[0];
    const jsonPart = validate.replace(re, '');
    //去掉首尾的括号
    const str = jsonPart.substring(1, jsonPart.length - 1);
    const responseObject = JSON.parse(str);
    const validate2 = responseObject.data.validate;
    if (validate2) {
      // if (false) {
      const res3 = await loginAccount({
        username,
        password,
        challenge,
        validateId,
        validate: validate2,
        deviceId,
      });
      if (res3.data?.at) {
        resolve(res3.data.at);
      }
    } else {
      // const res4 = await geeVisitGet2(gt, challenge);
      // const a = res4.split('(')[1].substring(0, res4.split('(')[1].length - 1);
      // const b = JSON.parse(a);
      // const { c, s, bg_path, block_path, challenge } = b;
      // debugger;
      resolve(false);
    }
  });
};
