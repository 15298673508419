import { useState } from 'react';

import downArrow from '@/assets/downArrow.png';
import logoTitle from '@/assets/logoTitle.png';
import message from '@/assets/message.png';
import messageListIcon from '@/assets/messageListIcon.png';
import { logout } from '@/services/login';
import { logoutCleanTooken } from '@/utils';
import { ExportOutlined } from '@ant-design/icons';
import { useModel } from '@umijs/max';
import { Avatar, Badge, List } from 'antd';
import dayjs from 'dayjs';

import styles from './index.less';

const data = [
  {
    title: 'Ant Design Title 1',
  },
  {
    title: 'Ant Design Title 2',
  },
  {
    title: 'Ant Design Title 3',
  },
  {
    title: 'Ant Design Title 4',
  },
];

export default () => {
  // 获取用户信息
  const { userInfo, failList } = useModel('global', (model) => ({
    userInfo: model.userInfo,
    setUserInfo: model.setUserInfo,
    failList: model.failList,
  }));
  // 消息列表状态
  const [messageListStatus, setMessageListStatus] = useState(false);
  // 操作列表状态
  const [operationListStatus, setOperationListStatus] = useState(false);

  // 消息列表划出隐藏事件，延迟0.3秒钟
  const handleMouseLeave = () => {
    setTimeout(() => {
      setMessageListStatus(false);
    }, 300);
  };

  // 操作列表划出事件，延迟0.3秒钟
  const handleOperationListMouseLeave = () => {
    setTimeout(() => {
      setOperationListStatus(false);
    }, 300);
  };

  // 退出登录
  const handleLogout = () => {
    logout();
    logoutCleanTooken();
  };

  return (
    <div className={styles.header}>
      <div className={styles.logo}>
        <img src={logoTitle} alt="" />
        <span>智联助手</span>
      </div>
      <div className={styles.messageAndUser}>
        <div className={styles.message} onMouseLeave={handleMouseLeave}>
          <Badge dot size="small">
            <img
              src={message}
              onClick={() => setMessageListStatus(true)}
              className={styles.message_icon}
              alt=""
            />
          </Badge>
          {
            // 消息列表
            messageListStatus && (
              <List
                className={styles.message_list}
                header={
                  <span className={styles.message_list_title}>消息通知</span>
                }
                dataSource={failList.slice(-5)}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={messageListIcon}
                          className={styles.message_list_icon}
                        />
                      }
                      title={
                        <a>
                          {item.type}: {item.msg}
                        </a>
                      }
                      description={
                        <span className={styles.message_list_description}>
                          {dayjs(item.time).format('YYYY-MM-DD HH:mm:ss')}
                        </span>
                      }
                    />
                  </List.Item>
                )}
              />
            )
          }
        </div>
        <div
          className={styles.user}
          onMouseLeave={handleOperationListMouseLeave}
        >
          <div className={styles.userImg}>
            <img src={logoTitle} alt="" />
          </div>
          <div
            className={styles.userName}
            onClick={() => setOperationListStatus(true)}
          >
            <span title="">{userInfo?.userName}</span>
            <img src={downArrow} alt="" />
          </div>
          {operationListStatus && (
            <div className={styles.operationList}>
              <div className={styles.operationList_item} onClick={handleLogout}>
                <ExportOutlined />
                <span>退出登录</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
