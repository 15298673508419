import { request } from '@/utils/request';
import { ResponseData } from '../types.d';
import { User } from './types.d';

// 获取用户信息
export const userInfoRequest = (data: any) => {
  return request<ResponseData<User>>('/user/query/phone', {
    method: 'post',
    data,
  });
};

// 续命在线时长 /login/keepLine
export async function keepAlive() {
  return request('/login/keepLine', {
    method: 'POST',
  });
}

// 续命token
export async function keepToken() {
  return request('/login/keepToken', {
    method: 'POST',
  });
}

// 修改用户信息 /user/update
export async function updateUserInfo(data: any) {
  return request('/user/update', {
    method: 'POST',
    data,
  });
}

// 获取交易码验证码 /user/sendTransactionCode
export async function sendTransactionCode() {
  return request('/user/sendTransactionCode', {
    method: 'POST',
  });
}

// 提交交易码修改 /user/editUserTransactionCode
export async function editUserTransactionCode(data: any) {
  return request('/user/editUserTransactionCode', {
    method: 'POST',
    data,
  });
}
