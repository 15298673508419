import { DEPLOY_ENV } from '@/config';
import { logoutCleanTooken } from '@/utils';
import { request as Request } from '@umijs/max';
import { message } from 'antd';

// 拦截器
export const requestInterceptors = (url: string, options?: any) => {
  // 获取tooken
  const token = localStorage.getItem('token');
  // 判断是不是域名
  const isDomain = url.includes('http');
  // // 获取cookie里的at字段
  // const str1 = options?.headers?.SuperCookie?.replace(/\s/g, '');
  // const cookieObj = str1?.split(';').reduce((preVal, curVal) => {
  //   const tempArr = curVal.split('=');
  //   return {
  //     ...preVal,
  //     [tempArr[0]]: tempArr[1],
  //   };
  // }, {});
  // const at = cookieObj ? cookieObj['at'] : undefined;
  // const headers = at
  //   ? { ...options.headers, SuperCookie: at ? `at=${at}` : undefined }
  //   : { ...options.headers, 'AUTH-TOKEN': isDomain ? undefined : token };

  const headers = {
    ...options.headers,
    'AUTH-TOKEN': isDomain ? undefined : token,
  };
  delete headers['fusheng'];
  delete headers['Fusheng'];
  if (isDomain) {
    delete headers['AUTH-TOKEN'];
  }
  return {
    url,
    options: {
      ...options,
      headers,
    },
  };
};

// 响应拦截器
export const responseInterceptors = (response: any) => {
  if (
    (response.data.code === 401 &&
      response.data.message === '您的登陆状态已失效,请重新登陆') ||
    response.data.message === 'at验证失败' ||
    response.data.message === '登录凭证已过期,请刷新页面后重试'
    // || response.config.headers.SuperCookie
  ) {
    // 触发事件
    window.dispatchEvent(
      new CustomEvent('loginFailed', {
        detail: response.config.headers.SuperCookie,
      }),
    );
  }

  if (
    response.data.errorCode === '300' &&
    response.data.errorMsg === '令牌过期'
  ) {
    logoutCleanTooken();
  }
  if (response.status !== 200) {
    message.error(response.data.errorMsg);
    return Promise.resolve(response);
  }
  if (response.data.success === false) {
    message.error(response.data.errorMsg);
    return Promise.resolve(response);
    // return response.data;
  }
  return response;
};

export const request = <T>(url: string, options: any): Promise<T> => {
  let Iurl = url;
  // 判断头部是否有http，没有的话加上/api
  if (!Iurl.includes('http')) {
    Iurl = DEPLOY_ENV === 'prod' ? url : `/api${url}`;
  }
  return Request(Iurl, {
    withCredentials: true,
    ...options,
    requestInterceptors: [requestInterceptors],
    responseInterceptors: [responseInterceptors],
  });
};
