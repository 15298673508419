export default (initialState: any) => {
  if (!initialState.userInfo) return false;
  return {
    adminRouteFilter: (route: any) => {
      if (['VIP', 'AGENT'].includes(initialState.userInfo?.userType)) {
        if (initialState.userInfo?.userType === 'VIP') {
          return route;
        }
        if (
          initialState.userInfo?.userType === 'AGENT' &&
          !['/cardInfo', '/onlineUsers'].includes(route.path)
        ) {
          return route;
        }
      }
      return false;
    },
  };
};
